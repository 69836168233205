<template>
  <div class="h5-solution-digitizing">

    <div class="h5-solution-digitizing-banner" :style="bannerImg">
      <!--      <v-img v-if="solutionInfo.h5Banner" :attachment-id="solutionInfo.h5Banner" :default-img="require('@/assets/img/kctp.png')" />-->
      <div>
        <p>{{ solutionInfo.name }}</p>
        <span>{{ solutionInfo.solSlogan }}</span>
        <van-button size="small" class="btn-red" @click="consult">国产替代咨询</van-button>
      </div>
    </div>

    <van-tabs
      v-model="active"
      scrollspy
      sticky
      color="#EA0B06"
      line-width="16px"
      :ellipsis=false
    >
      <van-tab title="方案介绍">
        <div class="h5-solution-digitizing-desc-box">
          <div class="h5-solution-digitizing-desc desc-title">
            <p>方案介绍</p>
            <div>
              <v-img :attachment-id="solutionInfo.solutionImg" :default-img="require('@/assets/img/kctp.png')"/>
              <p>{{solutionInfo.solutionDiscription}}</p>
            </div>
          </div>
        </div>
      </van-tab>
      <van-tab title="背景和价值">
        <div class="h5-solution-digitizing-val-box">
          <div class="h5-solution-digitizing-val desc-title">
            <p>背景和价值</p>
            <div>
              <p>{{solutionInfo.backgroundValue}}</p>
            </div>
          </div>
        </div>
      </van-tab>
      <van-tab title="行业分析" v-if="solutionInfo.datSolutionIndustries.length>0">
        <div class="h5-solution-digitizing-analyze-box">
          <div class="h5-solution-digitizing-analyze desc-title">
            <p>行业分析</p>
            <div class="content">
<!--              <p class="title">全国同类型建设分布图</p>-->
              <div>
                <van-swipe class="my-swipe" :autoplay="3000" indicator-color="#EA0B06">
                  <van-swipe-item v-for="i in solutionInfo.datSolutionIndustries" :key="i.id">
                    <p class="title">{{i.imgTitle}}</p>
                    <v-img class="img" :attachment-id="i.imgAttmId" :default-img="require('@/assets/img/kctp.png')"/>
                  </van-swipe-item>
                </van-swipe>
              </div>
            </div>
          </div>


        </div>

      </van-tab>
      <van-tab title="产品架构">
        <div class="h5-solution-digitizing-arc-box">
          <div class="h5-solution-digitizing-arc desc-title">
            <p>产品架构</p>
            <div>
              <v-img class="img" :attachment-id="solutionInfo.frameworkImg" :default-img="require('@/assets/img/kctp.png')"/>
            </div>
          </div>
        </div>

      </van-tab>


      <van-tab title="功能清单" v-if="solutionInfo.datSolutionFunctions.length>0">
        <div class="h5-solution-digitizing-list-box">
          <div class="h5-solution-digitizing-list desc-title">
            <p>功能清单</p>
            <div class="list">
              <div class="list-title">
                <div>功能名称</div>
                <div>功能点</div>
              </div>
              <div v-for="(item,index) in solutionInfo.datSolutionFunctions" class="list-item" :key="item.id" v-show="listBool.list>=index">
                <div class="item-name">{{ item.name }}</div>
                <div class="item-content">
                  <div v-for="(_item,_index) in item.datSolutionFunctionpoints" :key="_item.id" v-show="listBool.item>=_index">{{ _item.name }}</div>
                </div>
              </div>
            </div>

            <div v-if="solutionInfo && (solutionInfo.datSolutionFunctions.length>1 || solutionInfo.datSolutionFunctions[0].datSolutionFunctionpoints.length>7)" class="list-btn" @click="listChange">{{listBtn}}</div>



          </div>
        </div>

      </van-tab>


    </van-tabs>


  <solution-bottom v-if="!isLogin"></solution-bottom>
  <share :val="shareInfo"/>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import { getSolutionInfo } from '@/api/solution'
import VImg from '@/components/VImg'
import solution_bottom from '@/page/solution/components/solution_bottom'
import share from '../components/share.vue'
export default {
  name: 'digitizing',

  components: {
    VImg,
    share,
    'solution-bottom':solution_bottom,
  },

  data () {
    return {
      active: 0,
      solutionInfo: '',


      listBool:{
        list:0,
        item:7,
      },

      listBtn:'展开',

     /*  bannerImg: {
        backgroundImage: 'url(' + require('@/assets/img/digitizing1.png') + ')'
      }, */
      shareInfo: {
          title: '',
          desc: '',
          img: ''
      }
    }
  },

  computed: {
    ...mapGetters(["isLogin"]),
    id: function () {
      return this.$route.query.id
    },
    bannerImg:function (){
      let src=`${process.env.VUE_APP_OBS_PREFIX}/${this.solutionInfo.h5Banner}`
      return {backgroundImage: 'url(' + src + ')'}
    }
  },

   mounted(){
     document.title = '寻｜解决方案'
     this.loadGetSolutionInfo()
   },


  methods: {

    listChange(){
      let lengthList=[]
      let list=this.solutionInfo.datSolutionFunctions
      list.forEach(item=>{
         lengthList.push(item.datSolutionFunctionpoints.length)
       })

      let maxitem=Math.max(...lengthList)

      if(this.listBool.list>list.length){
        this.listBool.list=0
        this.listBtn='展开'
      }else {
        this.listBool.list=list.length+1
        this.listBtn='收起'
      }

      if(this.listBool.item>maxitem){
        this.listBool.item=7
      }else {
        this.listBool.item=maxitem+1
      }
    },


    consult () {
      localStorage.removeItem('orderVal')
      this.$router.push('/demandMove')

    },

     loadGetSolutionInfo(){

       getSolutionInfo(this.id).then((res)=>{
         this.solutionInfo = res
         this.shareInfo.title = res.name
        this.shareInfo.desc = res.solutionDiscription
        this.shareInfo.img = `${process.env.VUE_APP_OBS_PREFIX}/${res.h5Banner}`
       })
     },

  }

}
</script>

<style scoped lang="scss">
.h5-solution-digitizing {
  min-height: 100%;
  background: #f6f7f9;

  .h5-solution-digitizing-banner {
    height: 246px;
    color: #252b3a;
    background-repeat: no-repeat;
    background-size: cover;
    box-sizing: border-box;
    position: relative;
    &::after{
      content: '';
      position: absolute;
      z-index: 2;
      top: 0;
      left: 0;
      bottom: 0;
      right: 0;
      background: linear-gradient(90deg, #EEF3F9 0%, rgba(238,243,249,0.5000) 100%);
    }
    > img {
      width: 100%;
      height: 100%;
      display: block;
    }

    > div {
      padding: 40px 16px;
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      z-index: 3;

      > p {
        font-size: 24px;
        line-height: 33px;
        margin-bottom: 6px;
      }

      > span {
        font-size: 12px;
        display: block;
        line-height: 17px;
        margin-bottom: 46px;
      }

      > bottom {
        display: block;
      }
    }
  }

  .desc-title {
    > p {
      font-size: 18px;
      line-height: 16px;
      color: #333333;
      font-weight: bold;
      padding-left: 10px;
      border-left: 3px solid #EA0B06;
      // margin-bottom: 22px;
    }
  }


  .h5-solution-digitizing-desc-box {
    //margin-bottom: 30px;
    padding: 24px 16px;
    background: url("../../assets/img/digitizing2.png") no-repeat;
    background-size: cover;

    .h5-solution-digitizing-desc {

      > div {
        margin-top: 18px;
        padding: 16px;
        background: #fff;

        > img {
          width: 100%;
          height: 176px;
          display: block;
        }

        > p {
          font-size: 12px;
          line-height: 18px;
          text-align: justify;
          margin-top: 16px;
        }
      }
    }
  }

  .h5-solution-digitizing-val-box {
    padding: 24px 16px;

    .h5-solution-digitizing-val {
      > div {
        margin-top: 18px;
        padding: 16px;
        background: #fff;

        > p {
          font-size: 12px;
          line-height: 18px;
          text-align: justify;
        }
      }
    }

  }

  .h5-solution-digitizing-analyze-box {
    padding: 0 16px;

    .h5-solution-digitizing-analyze {

      .content {
        margin-top: 18px;
        padding: 16px 46px 42px 46px;
        background: #FFFFFF;



        .my-swipe {
          .img {
            max-width: 100%;
            max-height: 100%;
            display: block;
            margin: 0 auto;
          }
          .title {
            font-size: 14px;
            color: #333333;
            font-weight: bold;
            margin-bottom: 16px;
            text-align: center;
          }
        }
      }


    }
  }

  .h5-solution-digitizing-arc-box {
    padding: 24px 16px;

    .h5-solution-digitizing-arc {
      > div {
        margin-top: 24px;
        max-width: 343px;
        max-height: 297px;
        .img {
          width: 100%;
          height: 100%;
          display: block;
          margin: 0 auto;
        }
      }
    }
  }

  .h5-solution-digitizing-list-box {
    padding: 24px 16px;
    background: #FFFFFF;
    .h5-solution-digitizing-list {
      box-sizing: border-box;
      > .list {
        border: #eeeeee 1px solid;
        box-sizing: border-box;
        margin-top: 24px;
        > .list-title {
          height: 40px;
          box-sizing: border-box;
          border-bottom: #eeeeee 1px solid;
          display: flex;
          justify-content: flex-start;
          >div{
            text-align: center;
            font-size: 12px;
            font-weight: bold;
            line-height: 40px;
            box-sizing: border-box;
          }
            >:nth-child(1){
              width: 122px;
              box-sizing: border-box;
            }
            >:nth-child(2){
              width: 220px;
              border-left: #eeeeee 1px solid;
            }
        }

        > .list-item {
          display: flex;
          justify-content: flex-start;
          font-size: 12px;
          box-sizing: border-box;
          >.item-name{
            box-sizing: border-box;
            width: 122px;
            text-align: center;
            display: table;
            color: #333333;
            border-bottom: #eeeeee 1px solid;
            display: -webkit-box;
            -webkit-box-pack: center;
            box-pack: center;
            -webkit-box-align: center;
            box-align: center;
          }
          >:not(:last-child){
           >.item-name{
             border-bottom: #eeeeee 1px solid;
           }
          }
          >.item-content{
            width: 220px;
            color: #666666;
            text-align: center;
            >div{
              padding: 8px 0;

              border-left: #eeeeee 1px solid;

              border-bottom: #eeeeee 1px solid;
            }
          }
        }
      }

      >.list-btn{
        text-align: center;
        font-size: 12px;
        color: #EA0B06;
        margin-top: 24px;
      }
    }
  }


}
</style>
<style>
.van-swipe__indicators {
  bottom: 0;
}

.van-swipe__indicator {
  background: #eee;
  width: 24px;
  height: 2px;
}

.van-swipe {
  padding-bottom: 10px;
}
</style>
